/** @jsx jsx */
import { jsx } from "theme-ui";
import * as React from "react";

import { SetPageTitle, PageTitle } from "./page_title";

const DefaultLayout = ({ pageContext, children }) => {
  const { title } = pageContext.frontmatter;

  return (
    <>
      <SetPageTitle title={title} />
      <PageTitle title={title} />
      {children}
    </>
  );
};

export default DefaultLayout;
