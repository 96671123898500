import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/default_layout.js";
import ArxivPaper from "./_arxiv_paper";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`My research interests lie in `}<em parentName="p">{`stochastic analysis`}</em>{`, `}<em parentName="p">{`combinatorics`}</em>{` and `}<em parentName="p">{`graph theory`}</em>{`. Currently I'm working on a project on scaling limits of directed random graphs jointly with `}<a parentName="p" {...{
        "href": "https://www.sertedonderwinkel.com/"
      }}>{`Serte Donderwinkel`}</a>{` and supervised by `}<a parentName="p" {...{
        "href": "http://www.stats.ox.ac.uk/~goldschm/"
      }}>{`Prof. Christina Goldschmidt`}</a>{`.`}</p>
    <h1>{`Reading groups`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/levy"
        }}>{`Lévy Processes`}</a>{`, Trinity 2021, co-organized by `}<a parentName="li" {...{
          "href": "https://holungrandomcorner.wordpress.com/about-me/"
        }}>{`Terence Tsui`}</a></li>
    </ul>
    <h1>{`Preprints`}</h1>
    <ul>
      <li parentName="ul">
        <ArxivPaper title="Universality for the directed configuration model with random degrees: metric space convergence of the strongly connected components at criticality" id="2105.11434" date="Updated November 2021" coAuthors={["s_donderwinkel"]} mdxType="ArxivPaper" />
      </li>
    </ul>
    <h1>{`Presentations`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://scaling-limits-digraphs.netlify.app/"
        }}>{`CDT Presentation`}</a>{`, December 2021`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      